import React from "react";
import Layout from "../components/layout";
import Fade from "react-reveal/Fade";

import novelscats from '../images/novelscats.png'
import shopslinker from '../images/logo-shopslinker.png'
import sitenavigation from '../images/sitenavigation.png'
import imagecloud from '../images/imagecloud.png'


const products = [
  {
    id: 1,
    name: "Novel Cats",
    description: "Discover a vast collection of free online novels at Novels-Cats. Whether you're into fantasy, romance, action, or slice of life, we have something for everyone. Browse male-oriented, female-oriented, and general categories to find your next favorite read. Enjoy uninterrupted reading with ad-supported chapters and join our community of book lovers today!",
    image: novelscats,
    link: "https://novels-cats.com"
  },
  {
    id: 2,
    name: "Shops Linker",
    description: "Shops Linker: Your Gateway to Seamless Shopping. Shops Linker is a powerful platform designed to connect shoppers with their favorite online stores and undiscovered gems. With a focus on efficiency and ease of use, Shops Linker simplifies the shopping journey by organizing and categorizing various e-commerce platforms in one place. Whether you're looking for exclusive brands, trending products, or niche stores, Shops Linker ensures a smooth and enjoyable browsing experience. Discover the ultimate tool for hassle-free online shopping today!",
    image: shopslinker,
    link: 'https://www.shopslinker.com/'
  },
  {
    id: 3,
    name: "Image Cloud",
    description: "Experience the convenience of our free Image Cloud, designed for seamless and secure image hosting. Upload, share, and access your images effortlessly without worrying about storage limits or hidden costs. Perfect for personal and professional use, our platform ensures high-speed uploads and reliable third-party cloud storage, empowering you to manage your visual content with ease. Whether for sharing moments or managing projects, our Image Cloud is your ultimate solution for hassle-free image storage.",
    image: imagecloud,
    link: "https://imagecloud.us.kg/"
  },
  {
    id: 4,
    name: "Site Navigation",
    description: "Discover the World of Possibilities with OrangeSkin Navigation *OrangeSkin offers a seamless and intuitive navigation experience tailored for independent sites. Designed with precision and a user-first approach, our platform connects you to diverse digital destinations with ease. Whether you're exploring e-commerce, creative portfolios, or unique online services, OrangeSkin ensures every click takes you closer to what you need. Dive into a world of organized discovery, powered by cutting-edge technology and an elegant, minimalistic interface. Experience effortless navigation like never before.",
    image: sitenavigation,
    link: "https://sitenavigation.ggff.net/"
  },
];

const ProductList = () => {
  return (
    <Layout>
      <Fade bottom cascade>
        <div className="max-w-7xl mx-auto mt-10 px-5">
          <h1 className="text-4xl font-bold text-center mb-10 text-white">
            Our Products
          </h1>
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
            {products.map((product) => (
              <div
                key={product.id}
                className="bg-lightblack p-5 rounded-xl shadow-lg flex flex-col items-center text-white"
              >
                <img
                  src={product.image}
                  alt={product.name}
                  className="w-full h-40 object-contain rounded-md mb-4"
                />
                <h2 className="text-xl font-bold mb-2">{product.name}</h2>
                <p className="text-sm opacity-75 mb-4">{product.description}</p>
                <a href={product.link} target="_blank" className="bg-gradient-to-r from-pink to-purple text-white py-2 px-4 rounded">
                  View Details
                </a>
              </div>
            ))}
          </div>
        </div>
      </Fade>
    </Layout>
  );
};

export default ProductList;
